html {
  font-family: 'Playfair Display', serif;
  font-family: 'Lexend', sans-serif;
}

.inner-border {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.outer-border {
  border: 2px solid #de9b72;
  height: 99%;
  width: 98%;
  padding: 6px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .outer-border {
    height: 94%;
  }
}

.mid-border {
  border: 6px solid #de9b72;
  height: 100%;
  width: 100%;
  padding: 6px;
  margin: auto;
}

.inner-border {
  position: relative;
  border: 2px solid #de9b72;
  height: 100%;
  width: 100%;
  margin: auto;
}

/* Decorations */
.corner-decoration {
  position: absolute;
  width: 3em;
  margin: -3px;
}

@media (min-width: 768px) {
  .corner-decoration {
    width: 3.5em;
    margin: -4px;
  }
}

@media (min-width: 992px) {
  .corner-decoration {
    width: 4em;
    margin: -5px;
  }
}

@media (min-width: 1200px) {
  .corner-decoration {
    width: 5em;
    margin: -6px;
  }
}

.corner-decoration.corner-left-top {
  left: 0;
  top: 0;
}

.corner-decoration.corner-right-top {
  top: 0;
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.corner-decoration.corner-right-bottom {
  right: 0;
  bottom: 0;
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.corner-decoration.corner-left-bottom {
  left: 0;
  bottom: 0;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.vertical-decoration {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 11em;
}

@media (min-width: 768px) {
  .vertical-decoration {
    width: 16em;
  }
}

@media (min-width: 992px) {
  .vertical-decoration {
    width: 20em;
  }
}

@media (min-width: 1200px) {
  .vertical-decoration {
    width: 27em;
  }
}

.vertical-decoration.top {
  top: 0;
}

.vertical-decoration.bottom {
  bottom: 0;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
